//Navbar component
$breakpointHeader: lg;

.a {
    font-size: 14px !important;
}

.navbar {
    padding-bottom: 20px;
    justify-content: space-between !important;

    @include media-breakpoint-down($breakpointHeader) {
        &::before {
            content: "";
            background-color: white;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1002;
        }
    }

    @include media-breakpoint-between(md,lg){
        // margin-top: 22px;
    }

    @include media-breakpoint-up(lg){
        // margin-top: 28px;
    }

    &__icons {
        padding-top: 5px;
        padding-bottom: 5px;
        height: fit-content;
        align-items: center !important;

        order: 2;

        & .navbar_separator {
            border-left: 1px solid $lines-and-box;
            height: 40px !important;
        }

        & .nav-access-item {
            text-decoration: none;
            cursor: pointer;
        }

        & .nav-access-item:first-child {
            @include media-breakpoint-up(lg){
                margin-left: 30px;
            }
        }

        @include media-breakpoint-up(lg){
            & .nav-access-item {
                width: 21px;
                height: 27px;
                margin-left: 30px;
            }
            padding-top: 15px;
            padding-bottom: 15px;
        }

        &--login {
            display: flex;
            align-items: center;
            @include media-breakpoint-down(lg) {
                padding-left: 14px;
            }
        }

        &--shopping {
            display: flex;
            align-items: center;
            margin-right: 9px;
            margin-left: 5px;
            @include media-breakpoint-up(lg) {
                margin-left: 20px;
            }

            &.nav-access-item {
                margin-left: 0 !important;
            }
        }
    }

    @include media-breakpoint-down($breakpointHeader) {

        &__header__content {
            z-index: 1004;
        }

        .navbar.navbar-expand-lg &-nav {
            overflow: auto;
            padding: 30px 16px;
            height: calc(100vh - 113px);
        }
    }

    &__collapse {
        @include media-breakpoint-down($breakpointHeader) {
            height: 100vh;
        }
        display: block !important;
        transition: height .3s;
        order: 3;

        &.collapse:not(.show) {
            height: 0;
        }

        @include media-breakpoint-up(lg){
            order: 1;
            margin-right: 15px;
            padding-top: 0;
        }

        @include media-breakpoint-down($breakpointHeader) {
            position: absolute;
            width: calc(100%);
            margin: 102px 0 0;
            overflow: auto;
            top: 0;
            left: 0;
            background: white;
            z-index: 600;
            box-shadow: inset 0 20px 30px -16px rgba(0, 0, 0, 0.1);

            @include media-breakpoint-only(xs){
                margin: 80px 0 0;
            }
        }

        .navbar-nav .nav-link{
            color: $clickable-links;
            cursor: pointer;
            padding: 8px 20px;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 700;
            font-size: 15.5px;
            line-height: 26px;
            background: $clickable-links;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            width: fit-content !important;

            img {
                width: 16px;
                height: 15px;
            }

            @include media-breakpoint-down($breakpointHeader){
                padding-left: 0;
                font-size: 14px !important;
            }

            @include media-breakpoint-down(sm){
                padding-right: 0;
            }
        }
    }

    &__store-item {
        @include media-breakpoint-up(lg){
            padding-right: 21px !important;
        }
    }

    &__toggler-icon {
        mask: url("../../img/icon/toggle-icon.svg");
        background-color: red;
        width: 15px;
        height: 10px;
        margin-right: 8px;
        order: 1;
        cursor: pointer;
        transition-property: background-image, width, height;
        transition-duration: 0s;
        transition-delay: 0.5s;

    }

    //My Profile Item, viene mostrato solo nella visualizzazione mobile/tablet della navbar
    &__nav-profile {
        border-top: 1px solid $lines-and-box;
        border-bottom: 1px solid $lines-and-box;
        padding: 5px 0;
    }

    .navbar-toggler {
        outline: none;
    }

    &__brand{
        @include media-breakpoint-up(xs){
            margin-left: 20px;
        }
        @include media-breakpoint-up(sm){
            margin-left: 25px;
            margin-top: 10px;
            // height: 74px;
        }
        @include media-breakpoint-up(md){
            margin-left: 40px;
        }
        @include media-breakpoint-only(xs){
            height: 66px;
        }
    }

    @include media-breakpoint-up(md){
        padding-right: 40px;
    }
}



.hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0 !important;
    margin: 0 8px 0 0;
    overflow: visible;

    &-box {
        width: 15px;
        height: 12px;
        display: inline-block;
        position: relative;
    }

    &-inner {
        display: block;
        top: 50%;
        margin-top: -2px;

        &,
        &::before,
        &::after {
            width: 15px;
            height: 2px;
            background-color: $main-icon;
            border-radius: 4px;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: -5px;
        }

        &::after {
            bottom: -5px;
        }
    }

    & .hamburger-inner {
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
            transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        }

        &::after {
            transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
}

.navbar-toggler.is-active {
    .hamburger .hamburger-inner,
    .hamburger .hamburger-inner::before,
    .hamburger .hamburger-inner::after {
        background-color: $main-icon;
    }

    .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .hamburger-inner::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }

    .hamburger-inner::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}
